import axios, { AxiosInstance } from 'axios';
import { atom, selectorFamily } from 'recoil';
import { User } from '../data/user';
import { getAPIBaseURL } from '../utils/env';

export const tokenState = atom({
  key: 'auth_token',
  default: '',
  effects: [
    ({ onSet, setSelf }) => {
      setSelf(localStorage.getItem('auth_token') ?? '');

      onSet(value => {
        localStorage.setItem('auth_token', value);
      });
    },
  ],
});

export const refreshTokenState = atom({
  key: 'refresh_token',
  default: '',
  effects: [
    ({ onSet, setSelf }) => {
      setSelf(localStorage.getItem('refresh_token') ?? '');

      onSet(value => {
        localStorage.setItem('refresh_token', value);
      });
    },
  ],
});

type clientStateType = {
  contentType: string;
  apiVersion?: number;
};
export const clientState = selectorFamily<AxiosInstance, clientStateType>({
  key: 'client',
  get:
    (params: clientStateType) =>
    ({ get }) => {
      const { contentType, apiVersion = 2 } = params;
      const token = get(tokenState);

      return axios.create({
        baseURL: getAPIBaseURL(apiVersion),
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': contentType ?? 'application/json',
        },
      });
    },
});

export const userState = atom<User | null>({
  key: 'user_state',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      const userJSON = localStorage.getItem('user_state');
      if (userJSON != null) {
        const user = JSON.parse(userJSON);
        setSelf(user);
      }

      onSet(newValue => {
        if (newValue) {
          localStorage.setItem('user_state', JSON.stringify(newValue));
        } else {
          localStorage.removeItem('user_state');
        }
      });
    },
  ],
});

export const affiliateSourceState = atom<string | null>({
  key: 'affiliate_source_state',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem('affiliate_source_state');
      setSelf(value);

      onSet(newValue => {
        if (newValue) {
          localStorage.setItem('affiliate_source_state', newValue);
        } else {
          localStorage.removeItem('affiliate_source_state');
        }
      });
    },
  ],
});
